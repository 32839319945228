import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const VelocityBankingExplainedVisuallyPage: React.FC = () => (
  <Layout>
    <SEO title="Velocity Banking Explained Visually" />
    <h1>Velocity Banking Explained Visually</h1>
  </Layout>
)

export default VelocityBankingExplainedVisuallyPage
